



























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiAgentWithdrawLists } from '@/api/finance/finance'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import WithdrawPane from '@/components/finance/agent-withdraw-pane.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsPagination,
        WithdrawPane,
        ExportData
    }
})
export default class UserWithdrawal extends Vue {
    /** S Data **/
    // 日期选择器
    pickerOptions = {
        shortcuts: [
            {
                text: '最近一周',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近一个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近三个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    picker.$emit('pick', [start, end])
                }
            }
        ]
    }

    apiAgentWithdrawLists = apiAgentWithdrawLists

    tableData = []
    // 顶部查询表单
    pager: RequestPaging = new RequestPaging()
    // 顶部查询表单
    form = {
        nickname: '', // 用户昵称
        user_sn: '', // 用户编号
        sn: '', // 提现单号
        type: '', // 提现类型 1-钱包余额；2-微信零钱；3-银行卡;4-微信收款码; 5-支付宝收款码
        status: ' ', // 提现状态 1-待提现 2-提现中 3-提现成功 4-提现失败 ' '-全部
        start_time: '',
        end_time: '',
        user_info: '' //会员信息 用户昵称/用户编号
    }
    // 各状态数量
    tabCount = {
        all: 0,
        status_wait: 0,
        status_ing: 0,
        status_success: 0,
        status_fail: 0
    }
    /** E Data **/

    /** S Methods **/
    splitTime() {
        if (this.tableData != null) {
            this.form.start_time = this.tableData[0]
            this.form.end_time = this.tableData[1]
        }
    }
    // 重置
    onReset() {
        this.tableData = []
        this.form.sn = ''
        this.form.type = ''
        this.form.start_time = ''
        this.form.end_time = ''
        this.form.user_info = ''

        this.getList()
    }
    // 提现列表
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiAgentWithdrawLists,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {
                this.tabCount = res?.extend
            })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
    }
    /** E Life Cycle **/
}
